<template>
  <section class="slopePage">
    <div class="container">
      <div class="row">
        <SaveNotification
          v-if="saveCalculationLink && publicLink"
          :link="saveCalculationLink"
          :publicLink="publicLink"
        />
      </div>
    </div>
    <div class="container" v-if="totalInfo">
      <plate-layout-for-total-pdf ref="plateLayoutImages" v-if="isShowSectorsImages" />
      <div class="row">
        <div class="slopePage__title" ref="title">{{ $t('message.result.head.title') }}</div>
      </div>
      <div class="results-header" ref="toPdf">
        <div class="results-header__item">
          <div class="number">
            {{ slopesCount }}
          </div>
          <div class="text">
            {{ word }}
          </div>
        </div>
        <div class="results-header__item">
          <div class="number">{{ totalInfo.total_square }} {{ $t('message.units.m2') }}</div>
          <div class="text">{{ $t('message.result.head.square') }}</div>
        </div>
        <div class="results-header__item">
          <div class="number">{{ totalInfo.total_volume }} {{ $t('message.units.m3') }}</div>
          <div class="text">{{ $t('message.result.head.volume') }}</div>
        </div>
      </div>
    </div>
    <ResultNav />
    <ResultMaterialsComputation @requestNewResults="requestNewResults" />
    <ResultFastener
      :fasteners="totalFasteners"
      :pdfStart="this.pdfStart"
      :isFastenersNeed="isFastenersNeed"
    />
    <addons :items="albums" v-if="totalInfo && albums.length" />
    <CalculationNotification />
    <ResultPlugins ref="resultPlugins" />
    <calculator-rating />
    <Prefooter @savePdf="savePdf" @saveXls="saveXls" />
  </section>
</template>

<script>
import { saveAs } from 'file-saver'
import { getPdfTotal, getXlsTotal } from '@/api'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { getFormattedWord } from '@/utils'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isSafari } from '@/utils/utils'
import ResultNav from '@/components/result/ResultNav.vue'
import ResultMaterialsComputation from '@/components/result/ResultMaterialsComputation.vue'
import ResultFastener from '@/components/result/ResultFastener.vue'
import ResultPlugins from '@/components/result/ResultPlugins.vue'
import Prefooter from '@/components/Prefooter.vue'
import CalculatorRating from '@/components/smart/CalculatorRating'
import Addons from '@/components/dump/addons'
import PlateLayoutForTotalPdf from '@/components/result/PlateLayoutForTotalPdf'
import { cloneDeep } from 'lodash'
import { stepNumbers } from '@/utils/stepNumbers'
import { region } from '@/utils/makeInitialRequest'
import UpdateStepMixin from '@/components/mixin/UpdateStepMixin'
import SaveNotification from '@/components/dump/SaveNotification.vue'
import CalculationNotification from '@/components/dump/CalculationNotification.vue'

export default {
  mixins: [UpdateStepMixin],
  data: () => ({
    fullPage: true,
    isLoading: false,
    computation: [],
    sections: [],
    pdfStart: false,
    isShowSectorsImages: false
  }),
  components: {
    SaveNotification,
    PlateLayoutForTotalPdf,
    Addons,
    CalculatorRating,
    ResultNav,
    ResultMaterialsComputation,
    ResultFastener,
    ResultPlugins,
    Prefooter,
    CalculationNotification
  },
  methods: {
    ...mapMutations({
      UPDATE_CATEGORY: 'UPDATE_CATEGORY',
      checkTNTokenAction: 'checkTNToken',
      updateCalculationResult: 'UPDATE_CALCULATION_RESULT'
    }),
    ...mapActions({
      requestSavedResult: 'requestSavedResult',
      addObjectId: 'addObjectId',
      getTotalResults: 'getTotalResults'
    }),
    async renderPdfImages() {
      this.isShowSectorsImages = !this.isShowSectorsImages
      await this.$nextTick()
      await this.$nextTick()
      const sectorsImages = this.sectors.map(sector => {
        return {
          [sector.uid]: this.$refs.plateLayoutImages.$refs[`svgComponent--${sector.uid}`][0].$refs
            .figure.outerHTML
        }
      })
      this.isShowSectorsImages = !this.isShowSectorsImages
      return Object.assign({}, ...sectorsImages)
    },
    async savePdf() {
      this.isLoading = true
      const {
        userInfo,
        slopesCount,
        word,
        totalInfo,
        computationPdf,
        albums,
        totalFasteners,
        sectors
      } = this
      const sectorsImages = await this.renderPdfImages()
      const clonedSectors = cloneDeep(sectors).map((sector, index) => {
        return {
          ...sector,
          sectorImage: sectorsImages[sector.uid],
          groups: this.resultSectors[index].fasteners_group
        }
      })
      getPdfTotal(
        slopesCount,
        word,
        totalInfo.total_square,
        totalInfo.total_volume,
        computationPdf,
        albums,
        totalFasteners,
        clonedSectors,
        this.$i18n.locale,
        region,
        userInfo.result?.id,
        this.publicCalculationLink
      )
        .then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          this.isLoading = false
          if (this.isSafari) {
            const fileName = this.$t('message.result.save.fileName')
            saveAs(file, fileName)
          } else {
            window.open(fileURL)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveXls() {
      this.isLoading = true
      const fileName = this.$t('message.result.save.fileName')
      const { computationPdf, totalFasteners, userInfo, slopesCount, totalInfo } = this
      let user = null
      if (this.publicCalculationLink) {
        user = {}
        user.link = this.publicCalculationLink
      }
      const header = {
        sectorsCount: slopesCount,
        totalSquare: `${totalInfo.total_square} ${this.localizatedUnits.m2}`,
        totalVolume: `${totalInfo.total_volume} ${this.localizatedUnits.m3}`
      }
      getXlsTotal(
        computationPdf,
        totalFasteners,
        'total',
        user,
        header,
        this.$i18n.locale,
        region,
        userInfo.result?.id
      ).then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        this.isLoading = false
        saveAs(file, fileName)
      })
    },
    requestNewResults() {
      this.isLoading = true
      this.requestResults()
    },
    getSavedResult(urlQuery) {
      this.requestSavedResult({ urlQuery })
    },
    requestResults() {
      this.allSlopes[0].material === '' ? this.$router.push('/') : this.getTotalResults()
    },
    updateMetric() {
      this.updateStep(stepNumbers.result)
    }
  },
  mounted() {
    const urlQuery = this.$route.query.object
    if (urlQuery) {
      this.getSavedResult(urlQuery)
    } else {
      this.requestResults()
    }
    const { tnt: token } = this.$route.query
    if (token) {
      this.checkTNTokenAction(token)
    }
  },
  watch: {
    totalInfo() {
      this.updateMetric()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      totalInfo: state => state.result.totalInfo,
      albums: state => state.result.totalInfo.albums,
      totalFasteners: state => state.result.fasteners,
      loadingStatus: state => state.pages.result.isLoading,
      sectors: state => state.slopes,
      saveCalculationLink: state => state.saveCalculationLink,
      publicLinkTemplate: state => state.publicLink
    }),
    ...mapGetters({
      unit: 'getUnit',
      defaultSvgParams: 'getDefaultSvgParams',
      allSlopes: 'allSlopes',
      result: 'getResult',
      publicCalculationLink: 'publicCalculationLink'
    }),
    isFastenersNeed() {
      return !!this.totalFasteners?.length
    },
    isSafari() {
      return isSafari()
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    slopesCount() {
      let total = 0
      for (let i = 0; i < this.allSlopes.length; i += 1) {
        total += this.allSlopes[i].slopeCount
      }
      return total
    },
    word() {
      const words = this.$t('message.result.head.words').sectors
      return getFormattedWord(words, this.slopesCount)
    },
    resultSectors() {
      return this.result.sections
    },
    localizatedUnits() {
      return this.$i18n.messages[this.$i18n.locale].message.units
    },
    publicLink() {
      return this.publicLinkTemplate && this.userInfo.result?.id
        ? `${this.publicLinkTemplate}${this.userInfo.result?.id}`
        : ''
    },
    computationPdf() {
      return this.result.total
    }
  }
}
</script>

<style lang="sass" scoped>
.slopePage
  overflow: hidden
  &__title
    +media((padding: (320: rem(32) 0 rem(27) rem(24), 992: rem(70) 0 rem(36))))
    +media((font-size: (320: rem(18), 68: rem(24))))
    font-weight: bold
  .results-header
    background: $white
    display: grid
    +media((grid-template-columns: (320: 1fr, 650: 1fr 2fr 2fr, 768: 2fr 4fr 7fr)))
    column-gap: rem(34)
    +media((margin: (320: 0 rem(9), 992: 0 rem(-15))))
    +media((padding: (320: rem(23) rem(26) rem(31), 768: rem(21) rem(30))))
    &__item
      color: $black
      display: flex
      flex-direction: column
      justify-content: center
      align-items: flex-start
      .number
        font-style: normal
        font-weight: bold
        margin-bottom: rem(10)
        +media((font-size: (320: rem(28), 768: rem(32), 992: rem(36))))
        +media((line-height: (320: rem(34), 768: rem(40), 992: rem(44))))
      .text
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(18)
        +media((padding-bottom: (320: rem(22), 650: 0)))
</style>

<template>
  <div class="container">
    <div class="computation-header">
      <div class="slopePage__title">
        <b>{{ $t('message.result.materialsComputation.title') }}</b>
      </div>
      <Toggler
        :switch-items="units"
        :selected="unit || ''"
        @updateToggledValue="updateUnit($event)"
      />
      <simple-btn color="red" @btnClicked="toggleMassEditModal">
        <template #icon>
          <span class="icon"
            ><img
              src="@/assets/img/mass-edit.svg"
              :alt="$t('message.result.materialsComputation.change')"
          /></span>
        </template>
        {{ $t('message.result.materialsComputation.change') }}
      </simple-btn>
    </div>
    <div class="computation">
      <div class="computation__head">
        <div class="computation__head-title">
          {{ $t('message.result.materialsComputation.table.material') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.result.materialsComputation.table.size') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.result.materialsComputation.table.ekn') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.result.materialsComputation.table.count') }}
          <br />
          <span> {{ $t('message.result.materialsComputation.table.computation') }} </span>
        </div>
        <div class="computation__head-title">
          {{ $t('message.result.materialsComputation.table.count') }} <br />
          <span>{{ $t('message.result.materialsComputation.table.package') }}</span>
        </div>
        <div class="computation__head-title scheme">
          {{ $t('message.result.materialsComputation.table.scheme') }}
        </div>
      </div>
      <div class="computation__body">
        <div v-for="(item, i) in computation" class="computation__item" :key="i">
          <div class="computation__item-img">
            <img :src="getMaterial(item.id).src" :alt="getMaterial(item.id).name" />
          </div>
          <div class="computation__item-material">
            <div class="name">
              {{ getMaterial(item.id).name }}
            </div>
            <div class="angle">
              <img src="@/assets/img/angle.svg" alt="angle" />
              <b>{{ getMaterial(item.id).angle }}%</b>
            </div>
          </div>
          <div class="computation__item-plate">
            <div class="size">{{ item.size }}</div>
            <div class="plate">
              <b>
                <span> {{ $t('message.result.materialsComputation.table.plate') }} </span>
                {{ item.plateType }}
              </b>
            </div>
            <div class="info" v-if="item.hasOwnProperty('plateInfo')">{{ item.plateInfo }}</div>
          </div>
          <div class="computation__item-ekn">{{ item.sku_hidden ? '' : item.sku }}</div>
          <div class="computation__item-count">{{ item.count }} {{ item.countUnit }}</div>
          <div class="computation__item-volume">
            <span>{{ item.countPackage }} {{ item.countUnit }}</span>
          </div>
          <div class="computation__item-eye">
            <tippy placement="left" theme="light" animation="shift-toward" arrow>
              <template #trigger>
                <div class="trigger">
                  <img src="@/assets/img/eye--table.svg" alt="eye" />
                </div>
              </template>
              <span class="tooltip-plate">
                <img :src="item.src" alt="" class="popover__plate-img" />
              </span>
            </tippy>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal">
      <mass-edit-modal
        @close="toggleMassEditModal"
        v-if="isMassEditModalOpen"
        @requestNewResults="requestNewResults"
      />
    </transition>
    <transition name="fading">
      <div class="modal-mask" v-if="isMassEditModalOpen" @click.self="toggleMassEditModal"></div>
    </transition>
  </div>
</template>

<script>
import SimpleBtn from '@/components/dump/simpleBtn'
import MassEditModal from '@/components/smart/massEditModal'
import Toggler from '@/components/dump/Toggler.vue'
import { mapGetters, mapMutations } from 'vuex'
import { getMaterialsUnits } from '@/components/helper'
export default {
  data: () => ({
    isMassEditModalOpen: false
  }),
  components: { Toggler, MassEditModal, SimpleBtn },
  methods: {
    ...mapMutations({
      setUnit: 'UPDATE_UNIT'
    }),
    getMaterial(id) {
      return this.allMaterials.find(p => p.id === id)
    },
    toggleMassEditModal() {
      this.isMassEditModalOpen = !this.isMassEditModalOpen
    },
    requestNewResults() {
      this.toggleMassEditModal()
      this.$emit('requestNewResults')
    },
    updateUnit(evt) {
      this.setUnit({ index: null, unit: evt.value })
      this.$emit('requestNewResults')
    }
  },
  computed: {
    ...mapGetters({
      allMaterials: 'allMaterials',
      result: 'getResult',
      unit: 'getUnit'
    }),
    computation() {
      return this.result.total
    },
    units() {
      return getMaterialsUnits(this.$i18n.messages[this.$i18n.locale].message)
    }
  }
}
</script>

<style lang="sass" scoped>
.computation-header
  display: flex
  +media((align-items: (320: flex-start, 480: center)))
  +media((flex-direction: (320: column, 480: row)))
  +media((justify-content: (320: flex-start, 480: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(17), 992: rem(51) rem(-15) rem(17))))
  +media((padding: (320: 0 rem(25), 992: 0)))
  .slopePage
    &__title
      padding: 0
      +media((margin-bottom: (320: rem(15), 480: 0)))
      +media((font-size: (320: rem(18), 768: rem(24))))
.slopePage
  .modal-mask
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(51, 51, 51, 0.41)
  .computation
    border-radius: rem(4) rem(4) 0 0
    color: $black
    overflow-y: auto
    margin: 0 rem(-15)
    display: grid
    &__head
      background: $semi-white
      display: grid
      +media((padding: (320: 0 rem(25), 768: 0)))
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      $t-xl: 6fr 11fr 6fr 4fr 5fr 6fr 4fr
      $t-l: 7fr 8fr 6fr 4fr 5fr 6fr 5fr
      $t-m: rem(200) rem(100) rem(60) rem(80) rem(80) rem(60)
      $t-s: rem(100) rem(100) rem(60) rem(80) rem(80) rem(60)
      +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
      &-title
        min-height: rem(60)
        display: flex
        flex-direction: column
        justify-content: center
        @extend .common__title-result
        text-align: left
        span
          font-weight: normal
          font-size: rem(12)
          margin-top: rem(6)
          line-height: 100%
        &:first-child
          +media((grid-column-start: (768: 2)))
    &__body
      width: 100%
      display: grid
      margin-top: rem(1)
      grid-template-columns: 1fr
      grid-template-rows: 1fr
      grid-row-gap: rem(1)
    &__item
      text-align: left
      background: $white
      align-items: center
      +media((padding: (320: rem(10) rem(25), 768: rem(10) 0)))
      // grid-template-columns: repeat(6, 1fr)
      column-gap: calc(100% / 48)
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      $t-xl: 6fr 11fr 6fr 4fr 5fr 6fr 4fr
      $t-l: 7fr 8fr 6fr 4fr 5fr 6fr 5fr
      $t-m: rem(200) rem(100) rem(60) rem(80) rem(80) rem(60)
      $t-s: rem(100) rem(100) rem(60) rem(80) rem(80) rem(60)
      +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
      width: 100%
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: rem(17)
      grid-row-gap: rem(1)
      min-height: rem(68)
      display: grid
      &-img
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        +media((display: (320: none, 768: flex)))
        img
          width: rem(92)
          max-width: 100%
      &-eye
        line-height: 0
        .trigger
          height: rem(36)
          width: rem(36)
          border-radius: rem(4)
          transition: .5s
          display: flex
          justify-content: center
          align-items: center
          cursor: help

          img
            transition: .5s
            filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(101%) contrast(93%)
          @media(any-hover: hover)
            &:hover
              background: $main
              img
                filter: invert(1)
.popover
  &__plate
    &-img
      max-width: rem(175)
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  // -webkit-transform: scale(1.1)
  opacity: 0
  // transform: scale(1.1)
  transform: translateY(-50%)
.fading-enter-active, .fading-leave-active
  transition: .5s
  opacity: 1
.fading-enter, .fading-leave-to
  // -webkit-transform: scale(1.1)
  opacity: 0
  // transform: scale(1.1)
  // transform: translateY(-10%)
</style>

<template>
  <addons :items="plugins" is-plugins v-if="plugins.length">
    {{ $t('message.result.plugins.title') }}
    <template #icon>
      <span v-html="getIcon('plugin')" class="link__icon"></span>
    </template>
  </addons>
</template>

<script>
import Addons from '@/components/dump/addons'
import IconMixin from '@/components/mixin/IconMixin'
export default {
  mixins: [IconMixin],
  components: { Addons },
  computed: {
    plugins() {
      return [
        {
          id: 0,
          name: this.$t('message.result.plugins.autocad'),
          link: this.$t('message.links.autocad')
        },
        {
          id: 1,
          name: this.$t('message.result.plugins.revit'),
          link: this.$t('message.links.revit')
        }
      ].filter(plugin => Boolean(plugin.link))
    }
  }
}
</script>

<style lang="sass" scoped>
.slopePage
  &__title
    +media((font-size: (320: rem(18), 768: rem(24))))
    font-weight: bold
    +media((margin: (320: rem(32) 0 rem(17), 992: rem(51) 0 rem(17))))
    padding: 0
.addons
  &__link
    font-style: normal
    font-weight: 500
    font-size: rem(14)
    line-height: rem(22)
    color: $black
    transition: .5s
    text-decoration: none
    display: flex
    flex-direction: row
    align-items: center
    text-align: left
    padding: 0 rem(28)
    min-height: rem(68)
    &-img
      padding-right: rem(28)
      transition: .5s
    &:hover
      color: $main
      .addons__link-img
        opacity: .7
  &__item
    background: $semi-white
    border: rem(1) solid $ec
    box-sizing: border-box
    cursor: pointer
    display: flex
    flex-direction: row
    align-items: center
    padding: 0
</style>

<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-wrapper">
      <div class="modal-header">
        <div class="modal-header__title">{{ $t('message.massEdit.title') }}</div>
        <sectors-type-filter
          :current-slope-type="currentSlopeType"
          v-if="isAllTypesSectorsExist && currentSlopeType"
          @filterSectorsByType="filterSectorsByType"
        />
        <button class="close" @click="close">
          <span v-html="getIcon('tooltip')"></span>
        </button>
      </div>
      <div class="modal-body">
        <materials-selections
          :current-slope-type="currentSlopeType"
          :current-picked-material="currentPickedMaterial"
          :new-material="newMaterial"
          v-if="currentSlopeType"
          @updateCurrentPickedMaterial="updateCurrentPickedMaterial"
          @setNewMaterial="setNewMaterial"
        />
        <filtered-sectors
          :current-selected-sectors="currentSelectedSectors"
          :current-slope-type="currentSlopeType"
          :current-picked-material="currentPickedMaterial"
          @addSectorToSample="addSectorToSample"
          @toggleAll="toggleAllSectorsInSample"
          v-if="currentSlopeType && currentPickedMaterial"
        />
        <thickness-update
          v-if="isShowControls"
          :new-thickness="newThickness"
          @updateThickness="updateThickness"
          :current-selected-sectors="currentSelectedSectors"
        />
        <controls @applyChanges="applyChanges" v-if="isShowControls" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SectorsTypeFilter from '@/components/smart/massEditModal/SectorsTypeFilter'
import FilteredSectors from '@/components/smart/massEditModal/FilteredSectors'
import MaterialsSelections from '@/components/smart/massEditModal/MaterialsSelections'
import Controls from '@/components/smart/massEditModal/Controls'
import ThicknessUpdate from '@/components/smart/massEditModal/ThicknessUpdate'
import IconMixin from '@/components/mixin/IconMixin'
const types = {
  mainSlope: 'mainSlope',
  contrSlope: 'contrSlope'
}
export default {
  mixins: [IconMixin],
  components: {
    ThicknessUpdate,
    Controls,
    MaterialsSelections,
    FilteredSectors,
    SectorsTypeFilter
  },
  name: 'MassEditModal',
  data: () => ({
    currentSlopeType: null,
    currentPickedMaterial: null,
    newMaterial: null,
    newThickness: null,
    currentSelectedSectors: []
  }),
  mounted() {
    this.currentSlopeType = this.defaultSelectedType
    this.$root.offBodyScroll()
  },
  beforeDestroy() {
    this.$root.onBodyScroll()
  },
  methods: {
    ...mapMutations({
      MASS_UPDATE_MATERIAL_IN_SECTORS: 'MASS_UPDATE_MATERIAL_IN_SECTORS'
    }),
    close() {
      this.$emit('close')
    },
    filterSectorsByType(type) {
      this.currentPickedMaterial = null
      this.newMaterial = null
      this.newThickness = null
      this.currentSlopeType = type
    },
    updateCurrentPickedMaterial(id) {
      this.currentSelectedSectors = []
      this.newThickness = null
      this.currentPickedMaterial = id
    },
    setNewMaterial(id) {
      this.newMaterial = id
    },
    addSectorToSample(id) {
      this.currentSelectedSectors = id
    },
    toggleAllSectorsInSample(arr) {
      this.currentSelectedSectors = arr
    },
    updateThickness(val) {
      this.newThickness = val
    },
    applyChanges() {
      this.MASS_UPDATE_MATERIAL_IN_SECTORS({
        sectors: this.currentSelectedSectors,
        newMaterial: this.newMaterial,
        newThickness: this.newThickness
      })
      this.$emit('requestNewResults')
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.slopes
    }),
    mainSlopeSectors() {
      return this.allSectors.filter(s => s.slopeType === types['mainSlope'])
    },
    contrSlopeSectors() {
      return this.allSectors.filter(s => s.slopeType === types['contrSlope'])
    },
    defaultSelectedType() {
      return this.mainSlopeSectors.length ? types['mainSlope'] : types['contrSlope']
    },
    isAllTypesSectorsExist() {
      return Boolean(this.mainSlopeSectors.length) && Boolean(this.contrSlopeSectors.length)
    },
    isShowControls() {
      return (
        !!this.currentPickedMaterial && !!this.newMaterial && !!this.currentSelectedSectors.length
      )
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  &-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    // height: 100%
    z-index: 4
    // background: rgba(51, 51, 51, 0.41)
  &-wrapper
    background: $white
    width: 100%
    height: rem(804)
    max-height: 100vh
    +media((padding: (320: rem(40) 0, 960: rem(60) 0 rem(50), 1240: rem(60) rem(80) rem(50))))
    .close
      @extend %clear-btn
      height: rem(40)
      // width: rem(40)
      +media((width: (0: rem(40), 600: auto)))
      margin-left: rem(40)
      +media((position: (0: absolute, 600: relative)))
      // position: absolute
      top: 0
      right: 0
      +media((transform: (0: translateY(-100%), 600: none)))
    .modal
      &-header, &-body
        margin: 0 auto
      &-header
        position: relative
        +media((max-width: (1040: rem(960))))
        width: 100%
        display: flex
        +media((align-items: (320: flex-start, 576: center)))
        +media((flex-direction: (320: column, 576: row)))
        justify-content: flex-start
        +media((padding: (320: 0 rem(25), 960: 0 rem(40), 1240: 0)))
        &__title
          font-style: normal
          font-weight: normal
          +media((font-size: (320: rem(20), 768: rem(28), 960: rem(32))))
          line-height: 105%
          color: $black
          padding-right: rem(20)
          text-align: left
          margin-right: auto
      &-body
        width: 100%
        height: rem(644)
        max-height: 70vh
        +media((max-width: (1040: rem(960), 1240: rem(1080))))
        +media((padding: (320: 0 rem(19) 0 rem(25), 960: 0 rem(34) 0 rem(40), 1240: 0 rem(54) 0 rem(60))))
        overflow-y: auto
        overflow-x: hidden
        scrollbar-width: thin
        scrollbar-color: rgba($main, .3) transparent
        &::-webkit-scrollbar
          width: rem(6)
        &::-webkit-scrollbar-thumb
          background: $ec
          border-radius: rem(12)
        &::-webkit-scrollbar-thumb
          background-color: rgba($main, .3)
          border-radius: rem(12)
        &::-webkit-scrollbar-track
          background: $ec
          border-radius: rem(12)
</style>

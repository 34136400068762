import { render, staticRenderFns } from "./GradeItem.vue?vue&type=template&id=bdd1e0d0&scoped=true"
import script from "./GradeItem.vue?vue&type=script&lang=js"
export * from "./GradeItem.vue?vue&type=script&lang=js"
import style0 from "./GradeItem.vue?vue&type=style&index=0&id=bdd1e0d0&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd1e0d0",
  null
  
)

export default component.exports
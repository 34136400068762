<template>
  <div class="controls">
    <notify> {{ $t('message.massEdit.notify') }} </notify>
    <simple-btn color="red" @btnClicked="apply"> {{ $t('message.massEdit.submit') }} </simple-btn>
  </div>
</template>

<script>
import SimpleBtn from '@/components/dump/simpleBtn'
import Notify from '@/components/smart/massEditModal/Notify'
export default {
  name: 'Controls',
  components: { Notify, SimpleBtn },
  methods: {
    apply() {
      this.$emit('applyChanges')
    }
  }
}
</script>

<style scoped lang="sass">
.controls
  display: flex
  +media((align-items: (320: flex-start, 768: center)))
  +media((flex-direction: (320: column, 768: row)))
  justify-content: space-between
  +media((margin-top: (320: rem(34), 768: rem(51))))
</style>

<template>
  <div>
    <component
      v-for="sector in sectors"
      :key="sector.uid"
      :is="getFigureComponent(sector.slopeForm)"
      :slopeParamsProps="sector.slopeParams"
      :defaultSvgParams="defaultSvgParams"
      isPlate
      isDetails
      isForTotalPdf
      :sector="sector"
      :zoom="1"
      :isOffsetValleyNeed="isOffsetValleyNeed(sector)"
      :ref="`svgComponent--${sector.uid}`"
    >
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formsComponents, defaultSvgParams, checkIsOffsetValleyNeed } from '@/utils/drawing'

export default {
  props: {
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  name: 'PlateLayoutForTotalPdf',
  methods: {
    getFigureComponent(slopeForm) {
      return formsComponents[slopeForm]
    },
    isOffsetValleyNeed(sector) {
      const [slopeParams] = sector.slopeParams
      return checkIsOffsetValleyNeed(sector, slopeParams)
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.slopes
    }),
    defaultSvgParams: () => defaultSvgParams
  }
}
</script>

<style scoped></style>

<template>
  <div class="container">
    <div class="fasteners-head">
      <div class="slopePage__title">
        <b>{{ $t('message.result.fastener.title') }}</b>
        <tippy interactive placement="top" animation="shift-toward" arrow="false" :maxWidth="240">
          <template #trigger>
            <div class="title-tooltip">
              <span v-html="getIcon('tooltip')"></span>
            </div>
          </template>
          <div class="simple-tooltip__text">
            {{ tooltipText }}
          </div>
        </tippy>
      </div>
    </div>

    <div class="fastener" v-if="isFastenersNeed">
      <div class="fastener__head">
        <div class="fastener__head-title">{{ $t('message.result.fastener.table.title') }}</div>
        <div class="fastener__head-title">{{ $t('message.result.fastener.table.titleEKN') }}</div>
        <div class="fastener__head-title">
          {{ $t('message.result.fastener.table.count') }} <br /><span>{{
            $t('message.result.fastener.table.computation')
          }}</span>
        </div>
        <div class="fastener__head-title">
          {{ $t('message.result.fastener.table.count') }} <br /><span>{{
            $t('message.result.fastener.table.package')
          }}</span>
        </div>
      </div>
      <div class="fastener__item" v-for="(item, i) in filteredFasteners" :key="i">
        <div class="fastener__item-content fastener__item-content--img">
          <img :src="item.src" :alt="item.name" />
        </div>
        <div class="fastener__item-content fastener__item-content--name">{{ item.name }}</div>
        <div class="fastener__item-content">{{ item.sku_hidden ? '' : item.sku }}</div>
        <div class="fastener__item-content">{{ item.count }} {{ $t('message.units.pc') }}.</div>
        <div class="fastener__item-content">
          {{ item.countPackage }} {{ $t('message.units.pc') }}.
        </div>
      </div>
      <table-show-remaining-button
        :is-show-remaining="isShowRemaining"
        v-if="isShowRemainingBtn"
        @toggleRemaining="isShowRemaining = !isShowRemaining"
      >
        {{ showRemainingText }}
      </table-show-remaining-button>
    </div>
  </div>
</template>

<script>
import TableShowRemainingButton from '@/components/dump/TableShowRemainingButton'
import IconMixin from '@/components/mixin/IconMixin'

const displayCount = 18
export default {
  mixins: [IconMixin],
  components: { TableShowRemainingButton },
  props: {
    pdfStart: Boolean,
    fasteners: Array,
    isFastenersNeed: Boolean
  },
  data: () => ({
    isShowRemaining: false
  }),
  computed: {
    sortedFasteners() {
      return [...this.fasteners].sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = -1
        } else if (nameA < nameB) {
          comparison = 1
        }
        return comparison
      })
    },
    filteredFasteners() {
      return this.isShowRemaining ? this.fasteners : this.fasteners.slice(0, displayCount)
    },
    tooltipText() {
      return this.isFastenersNeed
        ? this.$t('message.result.fastener.details')
        : this.$t('message.result.fastener.detailsOff')
    },
    showRemainingText() {
      return this.isShowRemaining
        ? this.$t('message.result.fastener.table.hide')
        : `${this.$t('message.result.fastener.table.show')} ${this.fasteners.length}`
    },
    isShowRemainingBtn() {
      return this.fasteners.length > displayCount
    }
  }
}
</script>

<style lang="sass" scoped>
.fasteners-head
  display: flex
  +media((align-items: (320: flex-start, 650: center)))
  +media((flex-direction: (320: column, 650: row)))
  +media((justify-content: (320: flex-start, 650: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(17), 992: rem(51) rem(-15) rem(17))))
  +media((padding: (320: 0 rem(25), 992: 0)))
  .slopePage
    &__title
      padding: 0
      +media((margin-bottom: (320: rem(15), 650: 0)))
      display: flex
      align-items: center
      +media((font-size: (320: rem(18), 68: rem(24))))
      .title-tooltip
        margin-left: rem(10)
        display: flex
        align-items: center
.fastener
  border-radius: rem(4) rem(4) 0 0
  color: $black
  overflow-y: auto
  margin: 0 rem(-15)
  display: grid
  &__head
    display: grid
    @extend .common__result-header
    $t-s: rem(100) rem(160) rem(60) rem(80) rem(80)
    $t-m: 3fr 7fr 4fr 4fr 5fr
    $t-l: 3fr 10fr 3fr 3fr 4fr
    +media((column-gap: (320: rem(24), 650: 0)))
    +media((grid-template-columns: (320: $t-s, 650: $t-m, 768: $t-l)))
    &-title
      min-height: rem(60)
      display: flex
      flex-direction: column
      justify-content: center
      @extend .common__title-result
      text-align: left
      span
        font-weight: normal
        font-size: 12px
        line-height: 100%
      &:first-child
        grid-column-start: 2
  &__item
    display: grid
    align-items: center
    $t-s: rem(100) rem(160) rem(60) rem(80) rem(80)
    $t-m: 3fr 7fr 4fr 4fr 5fr
    $t-l: 3fr 10fr 3fr 3fr 4fr
    +media((column-gap: (320: rem(24), 650: 0)))
    +media((grid-template-columns: (320: $t-s, 650: $t-m, 768: $t-l)))
    background: $white
    padding-top: rem(2)
    padding-bottom: rem(2)
    margin-bottom: rem(1)
    font-size: rem(14)
    line-height: rem(17)
    &-content
      font-style: normal
      font-weight: 500
      text-align: left
      &--name
        padding: rem(10) rem(10) rem(10) 0
      &--img
        display: flex
        justify-content: center
        align-items: center
        img
          max-width: rem(69)
</style>

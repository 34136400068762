<template>
  <div class="sectors">
    <div class="sectors__title">{{ $t('message.massEdit.sectors.title') }} {{ title }}</div>
    <div class="sectors__items">
      <button class="toggle" @click="toggleAll" v-if="filteredSectors.length > 1">
        <span class="icon">
          <img :src="toggleButtonKit.icon" alt="" />
        </span>
        <span class="text">
          {{ toggleButtonKit.text }} <br />
          {{ $t('message.massEdit.material.all') }}
        </span>
      </button>
      <div class="item" v-for="sector in filteredSectors" :key="sector.uid">
        <input
          type="checkbox"
          :value="sector.uid"
          :id="`sector--${sector.uid}`"
          v-model="computedCurrentSelectedSectors"
          class="item__input"
        />
        <label :for="`sector--${sector.uid}`" class="item__content">
          <span class="item__content-scheme">
            <component
              :is="getCurrentFigureComponent(sector.slopeForm)"
              :defaultSvgParams="defaultSvgParams"
              :navId="getRealIndex(sector.uid)"
              :isNav="true"
            >
            </component>
          </span>
          <span class="item__content-footer">
            <span class="index">
              <b>{{ getSectorIndex(sector.uid) }}</b>
            </span>
            <span class="count">x{{ sector.slopeCount }}</span>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { defaultSvgParams, formsComponents } from '@/utils/drawing'
export default {
  props: {
    currentSelectedSectors: {
      type: Array,
      default: () => ({}),
      required: true
    },
    currentSlopeType: {
      type: String,
      default: null,
      required: true
    },
    currentPickedMaterial: {
      type: Number,
      required: true
    }
  },
  name: 'FilteredSectors',
  data: () => ({}),
  methods: {
    getCurrentFigureComponent(slopeForm) {
      return formsComponents[slopeForm]
    },
    getRealIndex(uid) {
      return this.allSectors.findIndex(s => s.uid === uid)
    },
    getSectorIndex(uid) {
      const index = this.allSectors.findIndex(s => s.uid === uid) + 1
      return index <= 9 ? `0${index}` : index
    },
    toggleAll() {
      this.$emit('toggleAll', this.sampleOfAllSectors)
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.slopes
    }),
    defaultSvgParams: () => defaultSvgParams,
    filteredSectors() {
      const { currentSlopeType, allSectors, currentPickedMaterial } = this
      // prettier-ignore
      return currentSlopeType
        ? allSectors.filter(
          s => s.slopeType === currentSlopeType && s.material === currentPickedMaterial
        )
        : allSectors.filter(s => s.material === currentPickedMaterial)
    },
    title() {
      return this.$t('message.slopeTypesWords')[this.currentSlopeType]
    },
    toggleButtonKit() {
      return this.currentSelectedSectors.length
        ? {
          text: this.$t('message.massEdit.sectors.unselect'),
          icon: require('@/assets/img/mass-edit--unselect.svg')
        }
        : {
          text: this.$t('message.massEdit.sectors.select'),
          icon: require('@/assets/img/mass-edit--select.svg')
        }
    },
    sampleOfAllSectors() {
      return this.currentSelectedSectors.length ? [] : this.filteredSectors.map(s => s.uid)
    },
    computedCurrentSelectedSectors: {
      get() {
        return this.currentSelectedSectors
      },
      set(val) {
        this.$emit('addSectorToSample', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.sectors
  +media((margin-top: (320: rem(34), 768: rem(51))))
  &__title
    @extend %mass-edit-title
  &__items
    +media((margin-top: (320: rem(18), 768: rem(30))))
    display: grid
    +media((grid-template-columns: (320: repeat(2, 1fr), 420: repeat(3, 1fr), 576: repeat(4, 1fr), 660: repeat(5, 1fr), 768: repeat(6, 1fr))))
    .toggle
      @extend %clear-btn
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      margin: rem(8)
      border: rem(1) dashed $tn_gray
      border-radius: rem(4)
      transition: .5s
      @media (any-hover: hover)
        &:hover
          border-color: $main
      .icon
        margin-bottom: rem(11)
      .text
        font-family: $main-font
        font-style: normal
        font-weight: normal
        font-size: rem(14)
        line-height: 120%
        color: $main
    .item
      &__input
        position: absolute
        display: none
        pointer-events: none
        opacity: 0
        &:checked
          + .item__content
              &:before
                background-color: $main
                border-color: $main
                background-image: url("~@/assets/img/form-tick.svg")
      &__content
        display: flex
        flex-direction: column
        cursor: pointer
        position: relative
        border: rem(1) solid $ec
        padding: rem(10)
        &-scheme
          +media((height: (320: rem(70), 960: rem(104))))
          display: flex
          align-items: center
          .figure__wrapper
            height: 100%
            display: flex
            align-items: center
            ::v-deep svg
                max-height: 100%
        &:before
          content: ""
          position: absolute
          right: rem(4)
          top: rem(4)
          height: rem(18)
          width: rem(18)
          border: rem(1) solid $tn_gray
          border-radius: rem(4)
          transition: .5s
          pointer-events: none
          background-repeat: no-repeat
          background-position: center
        &-footer
          display: flex
          align-items: center
          justify-content: space-between
          font-style: normal
          font-size: rem(14)
          color: $light_black
</style>

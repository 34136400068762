import { render, staticRenderFns } from "./RatingGrades.vue?vue&type=template&id=575c492d&scoped=true"
import script from "./RatingGrades.vue?vue&type=script&lang=js"
export * from "./RatingGrades.vue?vue&type=script&lang=js"
import style0 from "./RatingGrades.vue?vue&type=style&index=0&id=575c492d&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "575c492d",
  null
  
)

export default component.exports
<template>
  <div class="selections">
    <div class="selections__item">
      <div class="selections__item-title">{{ $t('message.massEdit.material.title') }}</div>
      <multiselect
        v-model="computedCurrentMaterial"
        :placeholder="$t('message.massEdit.material.placeholder')"
        label="name"
        track-by="id"
        :options="filteredMaterials"
        :option-height="68"
        :show-labels="false"
        :allow-empty="false"
        :searchable="false"
        open-direction="bottom"
      >
        <template #singleLabel="materials">
          <div class="option__box">
            <div class="option__img">
              <img :src="materials.option.src" :alt="materials.option.name" />
            </div>
            <div class="option__info">
              <div class="option__info-title">{{ materials.option.name }}</div>
              <div class="option__info-angle">
                <img src="@/assets/img/angle.svg" alt="" />{{ materials.option.angle }}%
              </div>
            </div>
          </div>
        </template>
        <template #option="materials">
          <div class="option__box">
            <div class="option__img">
              <img :src="materials.option.src" :alt="materials.option.name" />
            </div>
            <div class="option__info">
              <div class="option__info-title">{{ materials.option.name }}</div>
              <div class="option__info-angle">
                <img src="@/assets/img/angle.svg" alt="" />
                {{ materials.option.angle }}%
              </div>
            </div>
          </div>
        </template>
        <template #caret>
          <span class="multiselect__caret" v-html="getIcon('caret')"></span>
        </template>
      </multiselect>
    </div>
    <div class="selections__icon">
      <img src="@/assets/img/mass-edit--selections.svg" alt="" />
    </div>
    <div class="selections__item">
      <div class="selections__item-title">{{ $t('message.massEdit.material.changed') }}</div>
      <multiselect
        v-model="computedNewMaterial"
        :placeholder="$t('message.massEdit.material.placeholder')"
        label="name"
        track-by="id"
        :options="preparedMaterials"
        :option-height="68"
        :show-labels="false"
        :allow-empty="false"
        :searchable="false"
        open-direction="bottom"
      >
        <template #singleLabel="materials">
          <div class="option__box">
            <div class="option__img">
              <img :src="materials.option.src" :alt="materials.option.name" />
            </div>
            <div class="option__info">
              <div class="option__info-title">{{ materials.option.name }}</div>
              <div class="option__info-angle">
                <img src="@/assets/img/angle.svg" alt="" />{{ materials.option.angle }}%
              </div>
            </div>
          </div>
        </template>
        <template #option="materials">
          <div class="option__box">
            <div class="option__img">
              <img :src="materials.option.src" :alt="materials.option.name" />
            </div>
            <div class="option__info">
              <div class="option__info-title">{{ materials.option.name }}</div>
              <div class="option__info-angle">
                <img src="@/assets/img/angle.svg" alt="" />
                {{ materials.option.angle }}%
              </div>
            </div>
          </div>
        </template>
        <template #caret>
          <span class="multiselect__caret" v-html="getIcon('caret')"></span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { slopeTypes, filterMaterialsBySlopeType, filterMaterialsForMainSlope } from '@/utils'
import IconMixin from '@/components/mixin/IconMixin'
// import { sortMaterialsByAngle } from '@/utils'
export default {
  mixins: [IconMixin],
  props: {
    currentSlopeType: {
      type: String,
      required: true
    },
    currentPickedMaterial: {
      required: true
    },
    newMaterial: {
      required: true
    }
  },
  name: 'MaterialsSelections',
  components: { Multiselect },
  computed: {
    ...mapState({
      allMaterials: state => state.materials,
      allSectors: state => state.slopes
    }),
    currentSectors() {
      return this.allSectors.filter(s => s.slopeType === this.currentSlopeType)
    },
    currentUsedMaterials() {
      return this.currentSectors.map(s => {
        return s.material
      })
    },
    filteredMaterials() {
      return this.allMaterials.filter(p => this.currentUsedMaterials.includes(p.id))
    },
    materialsBySlopeType() {
      return filterMaterialsBySlopeType(this.allMaterials, this.currentSlopeType)
    },
    materialsForMainSlope() {
      return filterMaterialsForMainSlope(this.allMaterials)
    },
    preparedMaterials() {
      return this.currentSlopeType === slopeTypes.mainSlope
        ? this.materialsForMainSlope
        : this.materialsBySlopeType
    },
    computedCurrentMaterial: {
      get() {
        return this.currentPickedMaterial
          ? this.allMaterials.find(m => m.id === this.currentPickedMaterial)
          : null
      },
      set(val) {
        this.$emit('updateCurrentPickedMaterial', val.id)
      }
    },
    computedNewMaterial: {
      get() {
        return this.newMaterial ? this.allMaterials.find(m => m.id === this.newMaterial) : null
      },
      set(val) {
        this.$emit('setNewMaterial', val.id)
      }
    }
  }
}
</script>
<style lang="sass">
.selections
  &__item
    .multiselect__content-wrapper
    .multiselect__option--selected
      background: #eeeeee
    .multiselect__option--highlight
      background: $main
      .option__info-angle
        img
          filter: invert(1)
    .multiselect__option--selected.multiselect__option--highlight
      background: $main
      opacity: $hover-opacity
    .multiselect
      +media((width: (320: 100%, 768: 100%, 960: rem(400))))
      max-width: 100%
      min-height: rem(68)
      color: $black
      cursor: pointer
      &--active
        .multiselect__placeholder
          display: block
      .multiselect
        &__tags, &__single
          padding: 0
          margin: 0
          background: transparent
        &__tags
          padding: rem(10) rem(12)
          border: rem(1) solid $ec
          min-height: rem(68)
          display: flex
          align-items: center
          .multiselect__placeholder
            padding: 0
            margin: 0
        &__select
          height: 100%
          cursor: pointer
        &__caret
          display: flex
          justify-content: center
          transition: .5s
          cursor: pointer
          opacity: 1
          height: rem(6)
          width: rem(12)
          margin: 0
          top: 50%
          right: 100%
          transform: translate(-50%, -50%)
          position: absolute
          right: rem(18)
          border: none
      .option
        &__box
          display: flex
          flex-direction: row
          align-items: center
        &__img
          display: flex
          flex-direction: row
          align-items: center
          padding-right: rem(16)
          img
            height: rem(47)
        &__info
          font-size: rem(14)
          line-height: rem(17)
          padding-right: rem(40)
          &-title
            font-style: normal
            font-weight: 500
            padding-bottom: rem(6)
          &-angle
            font-family: $main-font
            font-style: normal
            font-weight: bold
            img
              padding-right: rem(6)
</style>
<style scoped lang="sass">
.selections
  +media((margin-top: (320: rem(34), 768: rem(51))))
  grid-template-columns: 1fr rem(60) 1fr
  +media((display: (320: flex, 768: grid, 960: flex)))
  +media((flex-direction: (320: column, 768: row)))
  +media((align-items: (768: center)))
  justify-content: space-between
  &__icon
    +media((min-height: (320: rem(50), 768: rem(68))))
    +media((transform: (320: rotate(90deg), 768: none)))
    margin-top: auto
    display: flex
    justify-content: center
    align-items: center
    align-self: center
  &__item
    &-title
      @extend %mass-edit-title
      +media((margin-bottom: (320: rem(8), 768: rem(12))))
</style>

<template>
  <div class="mass-edit__thickness" v-if="!isAllSelectedSectorsWithoutThickness">
    <notify v-if="!isSelectedSectorsThicknessEqual && isShowControls">
      {{ $t('message.massEdit.thickness.notify') }}
    </notify>
    <insulation-thickness
      :thickness="newThickness"
      v-if="isShowControls"
      @updateThickness="updateThickness"
    />
    <simple-btn color="red" @btnClicked="showThicknessControls" v-else>
      {{ $t('message.massEdit.thickness.submit') }}
    </simple-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SimpleBtn from '@/components/dump/simpleBtn'
import InsulationThickness from '@/components/dump/sector_fasteners/InsulationThickness'
import Notify from '@/components/smart/massEditModal/Notify'
export default {
  props: {
    currentSelectedSectors: {
      type: Array,
      required: true
    },
    newThickness: {
      type: Number,
      default: 0
    }
  },
  name: 'ThicknessUpdate',
  data: () => ({
    isShowControls: false
  }),
  components: { Notify, InsulationThickness, SimpleBtn },
  methods: {
    setCurrentThicknessToNewValue() {
      this.$emit('updateThickness', this.currentThickness)
    },
    showThicknessControls() {
      this.setCurrentThicknessToNewValue()
      this.isShowControls = !this.isShowControls
    },
    updateThickness(val) {
      this.$emit('updateThickness', val)
    }
  },
  watch: {
    currentThickness() {
      this.setCurrentThicknessToNewValue()
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.slopes
    }),
    currentThickness() {
      const [firstValue] = this.selectedSectorsThickness
      return this.isSelectedSectorsThicknessEqual ? firstValue : 0
    },
    selectedSectorsData() {
      return this.sectors.filter(sector => this.currentSelectedSectors.includes(sector.uid))
    },
    selectedSectorsThickness() {
      return this.selectedSectorsData
        .filter(sector => sector.fixtureCalculation.need)
        .map(sector => sector.fixtureCalculation.thickness)
    },
    isSelectedSectorsThicknessEqual() {
      const { selectedSectorsThickness: arr } = this
      return Math.min(...arr) === Math.max(...arr)
    },
    isAllSelectedSectorsWithoutThickness() {
      return this.selectedSectorsData.every(sector => sector.fixtureCalculation.need === false)
    }
  }
}
</script>

<style scoped lang="sass">
.mass-edit
  &__thickness
    margin-top: rem(32)
    display: flex
    flex-direction: column
    align-items: flex-start
    .notify
      margin-bottom: rem(20)
      &::v-deep
        .notify__text
          max-width: unset
</style>

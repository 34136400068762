<template>
  <div class="notify">
    <div class="notify__icon">
      <img src="@/assets/img/mass-edit--info.svg" alt="i" />
    </div>
    <div class="notify__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notify'
}
</script>

<style scoped lang="sass">
.notify
  +media((margin-bottom: (320: rem(20), 768: 0)))
  +media((padding-right: (320: 0, 768: rem(20))))
  display: flex
  align-items: center
  &__icon
    +media((margin-right: (320: rem(15), 768: rem(25))))
    line-height: 0
  &__text
    text-align: left
    font-style: normal
    font-weight: normal
    font-size: rem(12)
    line-height: 160%
    color: $light_black
    max-width: rem(386)
</style>

<template>
  <div class="filter">
    <div
      class="filter-item"
      :class="{ 'filter-item--active': currentSlopeType === item.type }"
      v-for="item in filters"
      :key="item.id"
      @click="filterSectorsByType(item.type)"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentSlopeType: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'SectorsTypeFilter',
  methods: {
    filterSectorsByType(type) {
      this.$emit('filterSectorsByType', type)
    }
  },
  computed: {
    filters() {
      return [
        {
          id: 0,
          text: this.$t('message.massEdit.filter.mainSlope'),
          type: 'mainSlope'
        },
        {
          id: 1,
          text: this.$t('message.massEdit.filter.contrSlope'),
          type: 'contrSlope'
        }
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.filter
  display: flex
  align-items: center
  box-shadow: inset 0 rem(-1) 0 #C4C4C4
  +media((margin-top: (320: rem(20), 576: 0)))
  &-item
    font-style: normal
    font-weight: bold
    font-size: rem(14)
    line-height: rem(17)
    color: $tn-gray_plus
    cursor: pointer
    +media((padding: (320: rem(8) 0 rem(12), 768: rem(8) 0 rem(25))))
    &:not(:last-child)
      margin-right: rem(30)
  &-item
    &--active
      color: $black
      box-shadow: inset 0 rem(-4) 0 $main
</style>
